<template>
  <div>
    <!-- modal -->
    <b-modal
      id="IndexPurchaseImageModel"
      :ok-only="false"
      ok-title="ปิด"
      modal-class="modal-primary"
      :title="'ข้อมูล การซื้อรูป'"
      size="xl"
      hide-footer
      @ok="clickCancel"
    >
      <b-row v-if="results">
        <b-col v-for="item in _aboutData" :key="item.icon" md="2" sm="6" class="mb-2 mb-md-4" :class="item.customClass">
          <br />
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.variant">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.data }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col sm="12" v-show="false">
          <table class="table">
            <tr>
              <template v-for="item in _aboutTopic">
                <th>{{ item.title }}</th>
                <td>
                  <font color="white">
                    <u>{{ item.value }}</u>
                  </font>
                </td>
              </template>
            </tr>
          </table>
        </b-col>

        <b-col sm="12" class="text-center">
          <img :src="results.cloudflare_url" class="img-fluid" alt="" />
        </b-col>
        <b-col sm="12">
          <b-button class="mt-3" block @click="$bvModal.hide('IndexPurchaseImageModel')">ปิด</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
export default {
  props: {
    role: String,
  },
  data() {
    return {
      results: '',
    }
  },
  computed: {
    _aboutData() {
      // debugger
      const {
        // customer_address_json: { firstName, lastName, gender, birth_date, email, phone_number },
        // transaction_datas: { credit_balance, credit_amount, credit_total, created_at },
        topic_image_data: { name, image_thumbnail_preview },
      } = this.results
      const firstName = this.results.customer_address_json?.firstName
      const lastName = this.results.customer_address_json?.lastName
      const birth_date = this.results.customer_address_json?.birth_date
        ? this.$date(this.results.customer_address_json?.birth_date).format('YYYY-M-D')
        : ''
      const email = this.results.customer_address_json?.email
      const phone_number = this.results.customer_address_json?.phone_number
      const gender = this.results.customer_address_json?.gender
      const credit_amount = this.results.transactionDatas?.credit_amount ?? 0
      return [
        {
          title: 'ชื่อ สกุล',
          data: `${firstName ?? ''} ${lastName ?? ''}`,
          icon: 'UserCheckIcon',
          variant: 'primary',
        },
        {
          title: 'เพศ',
          data: `${gender}`,
          icon: 'GitMergeIcon',
          variant: 'primary',
        },
        {
          title: 'วันเกิด',
          data: birth_date,
          icon: 'CalendarIcon',
          variant: 'primary',
        },
        {
          title: 'อีเมล',
          data: `${email}`,
          icon: 'MailIcon',
          variant: 'primary',
        },
        {
          title: 'เบอร์',
          data: `${phone_number}`,
          icon: 'PhoneIcon',
          variant: 'primary',
        },
        {
          title: 'เครดิตลด',
          data: `${credit_amount}`,
          icon: 'CreditCardIcon',
          variant: 'danger',
        },
      ]
    },

    _aboutTopic() {
      const {
        topic_image_data: { name, price },
        // transaction_datas: { created_at },
      } = this.results

      return [
        {
          title: 'หมวดรูปภาพ',
          value: name,
        },
        {
          title: 'ชื่อรูปภาพ',
          value: this.results.topic_image_list_datas.name,
        },
        {
          title: 'ราคา',
          value: this.results.topic_image_list_datas.price,
        },
        {
          title: 'วันเวลาที่ซื้อ',
          value: this.$date(this.results?.transaction_datas?.created_at ?? this.results?.created_at).format(
            'YYYY-M-D HH:mm:ss',
          ),
        },
      ]
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    async show(data) {
      // console.log('data', data)

      this.results = await this.api.get(
        `api/admin/userTransactionData/show-image-purchase/${data.image_data_transaction_data_as_id}`,
      )
      this.$bvModal.show('IndexPurchaseImageModel')
    },

    async clickSave(data) {},
    clickCancel() {},
  },
}
</script>
